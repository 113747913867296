<template>
    <div>
		<b-modal ref="modalEditQualification" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("global.titre_qualification") }}
				</template>
			</template>

			<div>
				<div v-if="!horse_ids && !tiers_ids">
					<label>{{ $t('monte.edit_qualif_for') }}</label>
					<e-select
						id="type_qualif"
						v-model="type_selected"
						track-by=""
						:placeholder="$t('monte.type_edit_qualif')"
						:selectedLabel="$t('global.selected_label')"
						:options="type_edit_qualif"
						:allow-empty="false"
						:show-labels="false"
						@input="setQualificationType"
					>
						<template slot="option" slot-scope="{ option }">{{ $t('monte.edit_qualif.'+option) }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.edit_qualif.'+option) }}</template>
					</e-select>
				</div>

				<template v-if="type_selected">
					<div class="my-2">
						<span>{{ $t('monte.edit_qualif_drag') }}</span>
		                <div class="row mt-2">
		                    <div class="col-6 pr-0 pr-md-3">
		                        <h5>{{ $t('monte.qualif_dispo') }}</h5>
		                        <draggable class="list-group list-group-left" :list="qualifications_left" v-bind="dragOptionsAllColumns" group="colonne">
		                            <div class="list-group-item  d-md-flex align-items-center " v-for="(qualif, index) in qualifications_left" :key="index">
		                                {{ qualif.qualification_libelle }}
		                                <div class="btn btn-secondary ml-auto" @click="select_column(qualif)">
		                                    <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
		                                </div>
		                            </div>
		                        </draggable>
		                    </div>
		                    <div class="col-6 pl-0 pl-md-3">
		                        <h5>{{ $t('monte.qualif_selected') }}</h5>
		                        <draggable class="list-group" :list="qualifications_selected" v-bind="dragOptionsSelectedColumns" group="colonne">
		                            <div class="list-group-item d-md-flex align-items-center " v-for="(qualif, index) in qualifications_selected" :key="index">
		                                <div class="btn btn-secondary ml-auto order-md-last">
		                                    <font-awesome-icon :icon="['fal', 'sort-alt']" />
		                                </div><br class="d-md-none"/>
										<span v-if="qualif.ids && qualif.ids.length != nb_items">{{ qualif.qualification_libelle }} - {{ qualif.ids.length }} {{ text }}</span>
										<span v-else>{{ qualif.qualification_libelle }} - {{ nb_items }} {{ text }}</span>
		                            </div>
		                        </draggable>
		                    </div>
		                </div>
		            </div>
		        </template>

                <div class="text-center">
					<b-button class="mt-3 rounded-pill" variant="primary" @click="checkForm">{{ $t("action.valider") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Contract from '@/mixins/Contract.js'
import Qualification from '@/mixins/Qualification.js'
import Common from '@/assets/js/common'
import _uniqBy from 'lodash/uniqBy'
import draggable from 'vuedraggable'

export default {
	name: "ModalEditQualification",
	mixins: [Contract, Qualification],
	data () {
		return {
			contracts: [],
			type_edit_qualif: ['horse', 'tiers', 'cmep'],
			type_selected: null,
			processing: false,
			all_qualifications: [],
			qualifications_selected: [],
			qualifications_left: [],
			ids: [],
            dragOptionsAllColumns: {
                sort: false
            },
            dragOptionsSelectedColumns: {
                animation: 200
            },
			horse_ids: null,
			tiers_ids: null,
			nb_items: 0,
			text: this.getTrad('global.element')
		}
	}, 
	methods: {
		openModal(contracts) {
			this.contracts = contracts
			this.type_selected = null
			this.all_qualifications = []
			this.qualifications_selected = []
			this.qualifications_left = []

			this.$refs.modalEditQualification.show()
		},
		openModalFromHorse(horse_ids) {
			this.horse_ids = horse_ids
			this.type_selected = 'horse'
			this.all_qualifications = []
			this.qualifications_selected = []
			this.qualifications_left = []

			this.$refs.modalEditQualification.show()
			this.setQualificationType(false)
		},
		openModalFromTiers(tiers_ids) {
			this.tiers_ids = tiers_ids
			this.type_selected = 'tiers'
			this.all_qualifications = []
			this.qualifications_selected = []
			this.qualifications_left = []

			this.$refs.modalEditQualification.show()
			this.setQualificationType(false)
		},
		closeModal() {
			this.$refs.modalEditQualification.hide()
		},
		setQualificationType(withoutChildren = true) {
			this.loadQualifications(withoutChildren)
		},
		async loadQualifications(withoutChildren) {
			this.qualifications_left = []

			if(this.horse_ids) {
				this.ids = this.horse_ids
			}
			else if(this.tiers_ids) {
				this.ids = this.tiers_ids
			}
			else {
				this.ids = await this.getIdsFormObject()
			}

			this.nb_items = this.ids.length

			const type = await this.getQualificationType(this.qualification_type)
			this.all_qualifications = await this.getQualifications(type.qualificationtype_id, withoutChildren)
	
			let qualifications_selected = []
			let datas = {}
			await Common.asyncForEach(this.ids.filter(i => i), async (id) => {
				const qualif = await this.getQualificationLinkByEntity(this.qualification_type, id)

				const qualif_ids = qualif.map(q => q.qualificationlink_qualification)
				qualif_ids.forEach(q => {
					let qual = this.all_qualifications.find(qu => qu.qualification_id == q)
					if(datas[qual.qualification_id]) {
						datas[qual.qualification_id].push(id)
					}
					else {
						datas[qual.qualification_id] = [id]
					}

					qualifications_selected.push(qual)
				})
			})

			this.qualifications_selected = _uniqBy(qualifications_selected, 'qualification_id')
			this.qualifications_selected.forEach(qualification => {
				qualification.ids = datas[qualification.qualification_id]
			})

            this.all_qualifications.forEach(qualif => {
                if(this.qualifications_selected.filter(q => q.qualification_id === qualif.qualification_id).length == 0) {
                    this.qualifications_left.push(qualif)
                }
            })

			if(this.qualifications_left.length == 0 && this.qualifications_selected == 0) {
				this.failureToast('toast.no_qualifs')
			}
		},
		getIdsFormObject() {
			if(this.type_selected == 'horse')
				return this.contracts.map(con => con.avenant.avenant_horse)
			if(this.type_selected == 'tiers')
				return this.contracts.map(con => con.contract_tiers[0].contracttiers_tiers)
			if(this.type_selected == 'cmep')
				return this.contracts.map(con => con.avenant.cmep.tiers_id)
		},
        select_column(qualif) {
            const index = this.qualifications_left.indexOf(qualif)
            this.qualifications_left.splice(index, 1)
            this.qualifications_selected.push(qualif)
        },
		async checkForm() {
			// const qualif_selected = this.qualifications_selected.map(qualif => qualif.qualification_id)
			const qualif_left = this.qualifications_left.map(qualif => qualif.qualification_id)
			await this.saveMultipleQualication(this.ids, this.qualifications_selected, qualif_left, this.qualification_type)

			this.horse_ids = null
			this.tiers_ids = null
			this.contracts = []

			this.$emit('updateQualif')

			this.closeModal()
			//toast + jument + cmep
		},
		reset_nb_items(qualifications) {
			qualifications.forEach(qualification => {
				if(qualification.ids) {
					qualification.ids = this.ids
				}
			})
		}
	},
	computed: {
		qualification_type() {
			if(this.type_selected == 'horse') {
				return 'horse'
			}
			return 'tiers'
		}
	},
	watch: {
		qualifications_left(val) {
			this.reset_nb_items(val)
		}
	},
	components: {
        draggable,
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
	}
}
</script>